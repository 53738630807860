import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./components/Loading/Loading";
import "./App.css";
// import ComingSoon from "./components/ComingSoon/ComingSoon";

const Error = React.lazy(() => import("./components/Error/Error"));
const SharedLayout = React.lazy(() =>
  import("./pages/SharedLayout/SharedLayout")
);
const Tour = React.lazy(() => import("./pages/Tour/Tour"));
const Plan = React.lazy(() => import("./pages/Plan/Plan"));
const Utils = React.lazy(() => import("./pages/Utilities/Utilities"));
const House = React.lazy(() => import("./pages/House/House"));
const Location = React.lazy(() => import("./pages/Location/Location"));
const Gallery = React.lazy(() => import("./pages/Gallery/Gallery"));

function App() {
  const [active, setActive] = useState(1);

  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Routes>
            <Route path="/" index element={<Loading />} />
            <Route
              element={
                <SharedLayout />
              }
            >
              <Route
                index
                path="/tour"
                element={
                  <Tour
                    active={active}
                    setActive={setActive}
                  />
                }
              />
              <Route
                index
                path="/plan"
                element={
                  <Plan />
                }
              />
              <Route
                index
                path="/utils"
                element={
                  <Utils />
                }
              />
              <Route
                index
                path="/house"
                element={
                  <House
                    active={active}
                    setActive={setActive}
                  />
                }
              />
              <Route
                index
                path="/location"
                element={
                  <Location />
                }
              />
              <Route
                index
                path="/gallery"
                element={<Gallery />}
              />
            </Route>
            <Route path="*" element={<Error />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
